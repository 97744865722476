import React, {FunctionComponent, useEffect, useState, useReducer} from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import Grid from "@material-ui/core/Grid/Grid";
import {Theme} from "@material-ui/core";
import {useIntl} from "react-intl";
import CrewResponseTreeView from "./CrewResponseTreeView";
import PhoenixApiManager, {
	AnsweredDecisionPoint, NextDecisionPoint
} from "../../../../../Api/PhoenixApiManager";
import VerticalStepper from "./DPVerticalStepper/DPVerticalStepper";
import Button from "@material-ui/core/Button";
import {RouteComponentProps} from "react-router";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
//new import, remember to copy the treeReferenceButton to the page
import TreeReferenceButtonCRT from "./TreeReferenceButtonCRT";
import NotePadButton from "./NotePadButton";
import EventTreeMxGraphEditorView from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/MxGraphViews/EventTreeMxGraphEditorView';
import HCLApiManager from 'hcl-web-editor/app/Api/HclApiManager';
import { SettingsInputAntennaTwoTone } from "@material-ui/icons";
import Notepad from './Notepad/Notepad'
import HCLTreeMxGraphView from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/MxGraphViews/HCLTreeMxGraphView';


import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PopUpForCRT from './PopUpForCRT'
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Flowchart from '../../../../../Report/flowchartpdf.pdf'


const useStyles = makeStyles((theme: Theme) => ({
	fullHeight: {
		height: "100%"
	},

	navigation: {
		height: theme.spacing(5)
	},

	cardFrame: {
		maxHeight: 'calc(100vh - 114px)',
		minHeight: 'calc(100vh - 114px)',
	},

	header: {
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2)
	},

	stepper: {
		flexGrow: 1,
		overflowY: "auto"
	},

	card: {
		height: "100%",
		display: "flex",
		flexDirection: "column"
	}

}));

interface CFCrewResponseTreeBuilderViewProps extends RouteComponentProps<{ id: string, cfid:string }> {
	templateID: number;
	crtID: number;
	onResponseUpdated: () => void;
	handleCopyInCFM:(data:any)=>void;
}


//SO what really happened here?
//binding the criticalfunctions to the end kind of works like
//componentdidUpdate(), in the end, the result would be updated when things got changed
const CFCrewResponseTreeBuilderView: FunctionComponent<CFCrewResponseTreeBuilderViewProps> = (props) => {

	const [criticalFunction, setCriticalFunction] = useState(null);
	const [decisionPoints, setDecisionPoints] = useState(null); // decisionPoints state from here
	const [answeredDecisionPoints, setAnsweredDecisionPoints] = useState(null);
	const [nextDecisionPoint, setNextDecisionPoint] = useState(null);
	const [crtID, setCrtID]=useState(null);
	const [templateID,setTemplate]=useState(null);
	const classes = useStyles();
	const [open, setOpen] = React.useState(true);
	const [doNotShowAgain, setDoNotShowAgain] = React.useState(false);


	const {match, history, onResponseUpdated,handleCopyInCFM } = props;
	const { params: { id, cfid } } = match;

	const modelId = parseInt(id);
	const cfId=parseInt(cfid);

	const handleDoNotShowAgainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		localStorage.setItem('doNotShowAgain', `${event.target.checked}`);
		setDoNotShowAgain(!doNotShowAgain)
	  };

	const openpopup = () => {
		<PopUpForCRT handleDoNotShowAgainChange={handleDoNotShowAgainChange} doNotShowAgain={doNotShowAgain}/>

	}

	const handleClose = () => {
		setOpen(false);
	  };

	useEffect(() => {
		PhoenixApiManager.getCfFunctionDetails(cfId)
		.then(response => response.json())
		.then(data => {
			setCrtID(data.model_data.crt.crt_tree_id);
			setTemplate(data.model_data.template);
			setCriticalFunction(data);
		})
		.catch(err => console.error(err));
		const savedDoNotShowAgain = localStorage.getItem('doNotShowAgain');

		if(savedDoNotShowAgain) {
			setDoNotShowAgain(true)
		} else {
			openpopup() 
		}
		
	}, [])

	useEffect(() => {
		if(templateID!=null){
		PhoenixApiManager.getTemplateTree(templateID)
			.then(response => { console.log(response.data.questionnaire.crt.decision_points)
				setDecisionPoints(response.data.questionnaire.crt.decision_points)}) // calls API to get decision points
			.catch(err => console.error(err));
		}
		PhoenixApiManager.getAnsweredDecisionPoints(cfId)
			.then(response => {console.log(response.data)
				setAnsweredDecisionPoints(response.data)})
			.catch(err => console.error(err));

		PhoenixApiManager.getNextDecisionPoint(cfId)
			.then(response => setNextDecisionPoint(response.data))
			.catch(err => console.error(err));

	}, [criticalFunction]);


	if (!decisionPoints || !answeredDecisionPoints || !nextDecisionPoint) {
		return (
			<CFCrewResponseTreeBuilderViewHeader modelId={modelId} cfid={cfId} />
		);
	}

	return (
		<div>
			<CFCrewResponseTreeBuilderViewHeader modelId={modelId} cfid={cfId} />
			<Grid container spacing={1}>
				<Grid item xs={7} className={classes.cardFrame}>
					<CrewResponseTreeView
						templateId={templateID}
						treeId={crtID}
						answeredDecisionPoints={answeredDecisionPoints}
						nextDecisionPoint={nextDecisionPoint}
						cfId={cfId}
					/>
				</Grid>
				<Grid item xs={5} className={classes.cardFrame}>
					<CFCrewResponseTreeBuilderControllerView
						modelId={modelId}
						cfId={cfId}
						decisionPoints={decisionPoints}
						answeredDecisionPoints={answeredDecisionPoints}
						nextDecisionPoint={nextDecisionPoint}
						onNext={() => history.push(`/model/${id}/main-analysis/${cfId}/bp`)}
						onResponseUpdated={onResponseUpdated}
						handleCopyInCFM={handleCopyInCFM}
						setNextDecisionPoint={setNextDecisionPoint}
						setAnsweredDecisionPoints={setAnsweredDecisionPoints}
						criticalFunction={criticalFunction}
					/>
				</Grid>
			</Grid>
			<PopUpForCRT
			handleClose={handleClose}
			handleDoNotShowAgainChange={handleDoNotShowAgainChange} doNotShowAgain={doNotShowAgain}
			/>
		</div>
	);
};

type CFCrewResponseTreeBuilderViewHeaderProps = {
	modelId: number,
	cfid: number
}

const CFCrewResponseTreeBuilderViewHeader: FunctionComponent<CFCrewResponseTreeBuilderViewHeaderProps> = (props) => {

	const intl = useIntl();
	const { modelId, cfid } = props;
	const [data, setData] = useState(null);
	const [cfname, setCfname] = useState('');


	const getMasterESD = () => {
		PhoenixApiManager.getMasterEsd(modelId)
		.then((response) => setData(response.data))
	}
	  
	useEffect(() => {
		getMasterESD();
	}, []);

	useEffect(() => {
		PhoenixApiManager.getCfFunctionDetails(cfid)
		.then(response => response.json())
		.then(data => {
			setCfname(data.title)
		})
		.catch(err => console.error(err));
	}, [cfid]);

	return (
		<div style={{ display:'flex', flexDirection:'row'}}>
			<NotePadButton
				cfId={cfid}
				title={'Notepad'}
				tooltipTitle={'Check Notepad'}/>
			<BreadcrumbsItem to={`/model/${modelId}/main-analysis/${cfid}`}>
				{intl.formatMessage({ id: `${cfname} ` })}
			</BreadcrumbsItem>
			<BreadcrumbsItem to={`/model/${modelId}/main-analysis/${cfid}/crt`}>
				{intl.formatMessage({ id: "analysis.crt" })}
			</BreadcrumbsItem>
			<TreeReferenceButtonCRT
				tooltipTitle={intl.formatMessage({ id: 'checkMasterESD' })}
				title="Master ESD"
				graphView={<HCLTreeMxGraphView data={data} />}
			/>
	
			{/* Button to open PDF in a new tab */}
			<a href={Flowchart} target="_blank" rel="noopener noreferrer">
				<Button 
					color="primary" 
					variant="contained"
					startIcon={<OpenInNewIcon />}
				>
					Open Flowchart PDF
				</Button>
			</a>
		</div>
	);
};

type CFCrewResponseTreeBuilderControllerViewProps = {
	modelId: number,
	cfId:number,
	decisionPoints: any,
	answeredDecisionPoints: AnsweredDecisionPoint[],
	nextDecisionPoint: NextDecisionPoint,
	onNext: () => void,
	onResponseUpdated: () => void,
	handleCopyInCFM:(data:any)=>void,
	setNextDecisionPoint: (data: any) => void,
	setAnsweredDecisionPoints: (data: any) => void;
	criticalFunction: any;
}

const CFCrewResponseTreeBuilderControllerView: FunctionComponent<CFCrewResponseTreeBuilderControllerViewProps> = (props) => {
	// decisionPoints some array which is needed to get questions and answers
	const { handleCopyInCFM,criticalFunction, modelId, cfId,decisionPoints, answeredDecisionPoints, nextDecisionPoint, onNext, onResponseUpdated, setNextDecisionPoint, setAnsweredDecisionPoints } = props;
	const classes = useStyles();
	const intl = useIntl();

	const [completedCriticalFunctions, setCompletedCriticalFunctions] = useState(null);
	const [copyCFID, setCopyCFID] = useState(null);

	const isNextDecisionPointAvailable = Object.keys(nextDecisionPoint || {}).length > 0;
    const [data, setData] = useState(null);
    const [selectedModel, setSelectedModel] = useState(null);
    const [selectedCF, setSelectedCF] = useState(null);

    useEffect(() => {
        PhoenixApiManager.getCFListForCopy(modelId).then((response) => {
            setData(response.data);
        }).catch((error) => console.error(error));
    }, [modelId]);

    const handleModelChange = (event) => {
        setSelectedModel(event.target.value);
        setSelectedCF(null);
    };

    const handleCFChange = (event) => {
        const selectedCFId = event.target.value;
        setSelectedCF(selectedCFId);
	

        const selectedModelData = data.find((model) => model.phoenix_main_model_id === selectedModel);
        const selectedCFData = selectedModelData?.cfs.find((cf) => cf.crt_model_id === selectedCFId);
		localStorage.setItem("copiedCFID", selectedCFId) // storing copy id in local storage because page refreshes and loses state
		localStorage.setItem("clickedCopyCF", "true")
		localStorage.setItem("copiedMainModel", selectedModel)
        if (selectedCFData) {
            const payload = {
                id: selectedCFData.id,
            };

            PhoenixApiManager.copyCRTWithCfId(modelId, criticalFunction.critical_function, payload).then((res) => {
                console.log(res);
				document.location.reload()
            }).catch((error) => {
                console.error(error);
            });
        }
    }; 

	
	// useEffect(()=>{
	// 	if(selectedCF) {
	// 		let payload = {
	// 			id: selectedCF
	// 		}
	// 		PhoenixApiManager.copyCRTWithCfId(modelId, cfId, payload)
	// 	}
	// }, [])

	// useEffect(() => { // this works with copying the critical function
	// 	if (copyCFID > 0) {
	// 		PhoenixApiManager.getAnsweredDecisionPoints(copyCFID) // copyCFID is just model id (GET /api/phoenix/model/{id}/crt/decision-point/)
	// 		.then(async response => {
	// 			console.log(response.data)

	// 			let r: any
	// 			for (r of response.data) {
	// 				await PhoenixApiManager.getNextDecisionPointForModelWithId(cfId, async (successResponce) => {
	// 					let resData = await successResponce.json()

	// 					let payload = JSON.stringify({
	// 						description: r.description,
	// 						name: r.name,
	// 						response: r.response
	// 					})

	// 					if (r.name == resData.name) {
	// 						await PhoenixApiManager.postDecisionPointResponseForModelWithId(cfId, payload)
	// 					}
	// 				}, () => {})
	// 			}
	// 			document.location.reload()
	// 		})
	// 		.catch(err => console.error(err));
	// 		handleCopyInCFM(copyCFID) // calls this function in analysisHeader which handles copy
	// 	}
		
	// }, [copyCFID])

	const getDPFromTemplateWithKey = key => {

		if (key in decisionPoints) {
			return decisionPoints[key]; // getting descision point with key
		}

		// remove last underscore with digits _d*
		const truncatedKey = key.replace(/(([_][\d]+)$)/, "");
		return decisionPoints[truncatedKey];
	};

	const generateAnsweredDecisionPointsQuestionAndResponse = () => { // called to generate the questions...
		if (!decisionPoints || !answeredDecisionPoints) return [];

		const intersectionTemplateAndAnswers = answeredDecisionPoints.filter(
			singleDP => {
				const templateDP = getDPFromTemplateWithKey(singleDP.name);
				return !(templateDP === null || templateDP === undefined);
			}
		);

		const templateAndAnswers = intersectionTemplateAndAnswers.map(singleDP => { // this generates the questions and descriptions
			const templateDP = getDPFromTemplateWithKey(singleDP.name); // calls this function with the name to get stuff
			return {
				name: singleDP.name,
				question: templateDP.question,
				description: templateDP.description,
				response_options: templateDP.response_options,
				user_response: {
					response: singleDP.response,
					description: singleDP.description
				}
			};
		});
		if (isNextDecisionPointAvailable) {
			const nextDPName = nextDecisionPoint.name;
			const templateDP = getDPFromTemplateWithKey(nextDPName);
			// next question if it exists
			templateAndAnswers.push({
				name: nextDPName,
				question: templateDP.question,
				description: templateDP.description,
				response_options: templateDP.response_options,
				user_response: {
					response: "",
					description: ""
				}
			});
		}
		return templateAndAnswers;
	};

	const doesDecisionPointExist = dp => {
		return answeredDecisionPoints.filter(e => e.name === dp).length > 0;
	};

	const handleAnswerUpdate = (event, updatedForm) => {
		const payload = JSON.stringify(updatedForm);
		const DP = updatedForm.name;


		if (!doesDecisionPointExist(DP)) {
			PhoenixApiManager.postDecisionPointResponseForModelWithId(cfId, payload)
				.then(response => response.status === 201 && response.json())
				.then(data => setAnsweredDecisionPoints([...answeredDecisionPoints, data]))
				.then(() => {
					PhoenixApiManager.getNextDecisionPoint(cfId)
						.then(response => setNextDecisionPoint(response.data))
						.catch(err => console.error(err));
				})
				.then(() => onResponseUpdated());
		} else {
			PhoenixApiManager.patchDecisionPointResponseForModelWithId(
				cfId,
				DP,
				payload
			)
				.then(response => response.status === 200 && response.json())
				.then(() => {
					PhoenixApiManager.getAnsweredDecisionPoints(cfId)
						.then(response => setAnsweredDecisionPoints(response.data))
						.catch(err => console.error(err));
				})
				.then(() => {
					PhoenixApiManager.getNextDecisionPoint(cfId)
						.then(response => setNextDecisionPoint(response.data))
						.catch(err => console.error(err));
				})
				.then(() => onResponseUpdated());
		}
	};

	return (
		<Card className={classes.card}>
            <div className={classes.header}>
                <Typography variant="h5">Copy Critical Function</Typography>
                <div>
                    <FormControl style={{ minWidth: '200px', marginBottom: '20px' }}>
                        <InputLabel id="model-select-label">Select Model</InputLabel>
                        <Select
                            labelId="model-select-label"
                            value={selectedModel}
                            onChange={handleModelChange}
                        >
                            <MenuItem value={null}><em>None</em></MenuItem>
                            {data && data.map((model) => (
                                <MenuItem key={model.phoenix_main_model_id} value={model.phoenix_main_model_id}>
                                    {model.phoenix_main_model_title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div>
                    <FormControl style={{ minWidth: '200px', marginBottom: '20px' }} disabled={!selectedModel}>
                        <InputLabel id="cf-select-label">Select CF</InputLabel>
                        <Select
                            labelId="cf-select-label"
                            value={selectedCF}
                            onChange={handleCFChange}
                        >
                            <MenuItem value={null}><em>None</em></MenuItem>
                            {selectedModel && data.find((model) => model.phoenix_main_model_id === selectedModel)?.cfs.map((cf) => (
                                <MenuItem key={cf.id} value={cf.crt_model_id}>
                                    {cf.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <Divider />
            </div>
			<div className={classes.stepper}>
				<VerticalStepper
					completed={!isNextDecisionPointAvailable}
					answered={generateAnsweredDecisionPointsQuestionAndResponse()} // This generates the questions...
					onAnswerUpdate={handleAnswerUpdate}
					cfid={cfId}
					modelId={modelId}
				/>
			</div>
			<div className={classes.navigation}>
				<Button
					className={classes.fullHeight}
					fullWidth
					variant="contained"
					color="primary"
					disabled={isNextDecisionPointAvailable}
					onClick={onNext}
				>
					{intl.formatMessage({ id: "analysis.next" })}
				</Button>
			</div>

		</Card>			

	);
};

export default CFCrewResponseTreeBuilderView;
