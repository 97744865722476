import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { injectIntl } from 'react-intl';
import createStyles from "@material-ui/core/styles/createStyles";
import Divider from "@material-ui/core/Divider";
import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";


//@ts-ignore
import CircularIndeterminate from 'hcla-web-frontend-primitives/app/components/primitives/Progress/CircularIndeterminate';

import BPHorizontalStepper from './BPHorizontalStepper/BPHorizontalStepper';
import BPViewer from './BranchPointDescriptionView';
import PhoenixApiManager from '../../../../../Api/PhoenixApiManager';
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import {PhoenixEventTreeMxGraph} from "../../PhoenixTreeMxGraphView";
import EndStateSelection from './EndstateSelection/EndStateSelection';


import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Notepad from '../DP/Notepad/Notepad';

import PopUpForBP from './PopUpForBP';
import TreeReferenceButtonBP from "./TreeReferenceButtonBP";
import EventTreeMxGraphEditorView from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/MxGraphViews/EventTreeMxGraphEditorView';
import HCLTreeMxGraphView from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/MxGraphViews/HCLTreeMxGraphView';
import NotePadButton from '../DP/NotePadButton';




const styles = (theme: Theme) => createStyles({
  message: {
    padding: theme.spacing(1) * 4,
    textAlign: 'center',
    color: '#888',
  },

  inline_message:{
    fontSize:11,
    textAlign: 'center',
    
  },
  fullHeight: {
    height: "100%"
  },

  navigation: {
    height: theme.spacing(5)
  },

  cardFrame: {
    maxHeight: 'calc(100vh - 114px)',
    minHeight: 'calc(100vh - 114px)',
  },

  header: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },

  stepper: {
    flexGrow: 1,
    overflowY: "auto"
  },

  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  }

});

interface Props {
  classes: any,
  intl: any,
  history: any,
  match: {
    url: string,
    params: any,
    path: string,
  },
  depth: number,
  templateID: number,
  
  onResponseUpdated: () => void,
  modelData: any,
  progress: any
}

interface State {
  answeredBranchPoints: any,
  nextBranchPoint: any,
  templateBranchPoints: any,
  focusedBranchPoint: any,
  cfname: any,
  isBuildingBP: Boolean,
  allBranchPoints: any,
  postRequestData: any,
  postRequestDataMap: any,
  answeredBPsMap: any,
  postBPLoading: boolean
}

class BranchPointDescriptionBuilder extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      answeredBranchPoints: {
        data: [],
        loading: true,
        loaded: false,
      },
      nextBranchPoint: {
        data: {},
        loading: true,
        loaded: false,
      },
      templateBranchPoints: {
        data: null,
        loading: true,
        loaded: false,
      },
      focusedBranchPoint: null,
      graph: null,
      descriptions: null,
      cfname: '',
      isBuildingBP: false,
      completedCriticalFunctions: null,
      copyCFID: null,
      masterESD:null,
      endStateNotSent:true,
      doNotShowAgain: false,
      allBranchPoints: {
        data: [],
        loading: true,
        loaded: false,
      },
      postRequestData: [],
      // will map name to index of array above
      postRequestDataMap: {},
      misc: null,
      answeredBPsMap: {},
      postBPLoading: false,
      estimatedBPTime: 0, 
      dataFromCopyList: null

    };    
  }


  handleEndStateSent=()=>{
    this.setState({
      endStateNotSent: false
    })
  }

  formatPayload = (endStates) => {
    const successSequences = endStates.filter(state => state.startsWith('ES_'));
    const failureSequences = endStates.filter(state => state.startsWith('EF_'));
  
    return {
      success_sequences: successSequences,
      failure_sequences: failureSequences,
      success: "End State",
      failure: "End State"
    };
  }
  
  

  componentDidMount = (prevProps, prevState) => {

    const { match: { params: { id, cfid } } } = this.props;

    // this.getBpNotes(cfid)
    const cfId=parseInt(cfid);
    // get branch points from template

    this.getMasterESD(id);
    let CRTId;

    PhoenixApiManager.getCFListForCopy(id).then((response) => {
      this.setState({
        dataFromCopyList: response.data
      })
    }).catch((error) => console.error(error));



    PhoenixApiManager.getTemplateWithId(this.getTemplateId())
      .then(response => response.status === 200 && response.json())
      .then(data => this.setState({
        templateBranchPoints: {
          data: data.questionnaire.crt.branch_points,
          loading: false,
          loaded: true,
        },
      }))
      .then(() => this.fetchAnsweredBranchPoints())
      .then(() => this.fetchNextBranchPoint());
    

    
    PhoenixApiManager.getCrewResponseTreeAnalysisEventTree(cfid)
      .then(response => {
        this.setState({
          graph: new PhoenixEventTreeMxGraph(response.data),
          descriptions: response.data.functional_events
        });
      })
      .catch(err => console.error(err));

      PhoenixApiManager.getCfFunctionDetails(cfid)
      .then(response => response.json())
      .then(data => {
          CRTId = data.critical_function;
          this.setState({ cfname: data.title });
          return PhoenixApiManager.getEndstates(id, CRTId); // added return to chain promises properly
      })
      .then(res => {
  
          const formattedPayload = this.formatPayload(res.data.end_states);
          
          // Check the conditions for success_sequence, failure_sequences and end_states:
          if ((formattedPayload.success_sequences.length === 0 || formattedPayload.failure_sequences.length === 0) && 
              res.data.end_states.length === 0) {
              alert("This critical function is not connected on your Master ESD, please return to the Master ESD and properly connect the graph");
              return Promise.reject('Endstate connection error');  // reject promise to stop further processing
          }
      
          // Set the formatted values in the state or wherever you need them:
          // setSuccessStr(formattedPayload.success);
          // setFailureStr(formattedPayload.failure);
  
          // Send the formatted payload:
          return PhoenixApiManager.postEndstates(id, CRTId, formattedPayload);
      })
      .then(response => {
          console.log(response); 
          this.handleEndStateSent();
      })
      .catch(err => {
          if (err === 'Endstate connection error') {
              // Handle the specific error if needed
          }
          alert('Endstate not loaded, please reload page!');
      });
  
    // here below we will get critical functions for that specifical model
    const mainModelIdOfCopied = localStorage.getItem("copiedMainModel")
    PhoenixApiManager.getMainCriticalFunctions(mainModelIdOfCopied)
    .then(async (data) => {
      let completedCFS = data.data.filter(item => {
        if (typeof item !== undefined && item.crt_model > 0 && item.crt_model !== cfId && item.progress.bp.summary.completed == item.progress.bp.summary.total)
          return true
        else
          return false
      })
      await this.setState({completedCriticalFunctions: completedCFS})
    })

    
    
  };

  componentDidUpdate(prevProps, prevState) {

    const { match: { params: { id, cfid } } } = this.props;
    const cfId=parseInt(cfid);
    // this.getBpNotes(cfid)

    if (this.state.copyCFID !== prevState.copyCFID) {
      
      if (this.state.copyCFID > 0) {
        PhoenixApiManager.getAnsweredBranchPointsForModelWithId(this.state.copyCFID)
        .then(response => {
          return response.json()
        }).then(async data => {
          
          let r: any
          for (r of data) {
            await PhoenixApiManager.getNextBranchPointForModelWithId(cfid, async (successResponce) => {
              let resData = await successResponce.json()

              let payload = {
                failure: r.failure,
                failure_probability: r.failure_probability,
                success: r.success,
                name: r.name,
                label: {
                  name: r.label.name,
                },
              }
              if (r.failure_probability)
                payload.failure_probability = r.failure_probability
              
              if (r.label.description)
                payload.label.description = r.label.description

              payload = JSON.stringify(payload)

              if (r.name == resData.name) {
                await PhoenixApiManager.postBranchPointResponseForModelWithId(cfid, payload)
              }
            }, () => {})
          }
          document.location.reload()
          
        })
        .catch(err => console.error(err));
      }
    }
  };

  getBpNotes = () => {

    // const { cfid } = this.props;
        PhoenixApiManager.openMainModelNote(this.props.match.params.cfid)
          .then(res => {
            this.setState({ misc: res.data.misc });
          });
  
  };
  

  getMasterESD = (modelId) => {
		PhoenixApiManager.getMasterEsd(modelId)
		.then((response) => this.setState({masterESD:response.data}))
	};


  fetchAnsweredBranchPoints = () => {
    // get branch points from template
    const { match: { params: { id,cfid } } } = this.props;
    const cfId=parseInt(cfid);
    return PhoenixApiManager.getAnsweredBranchPointsForModelWithId(cfid)
      .then(response => response.status === 200 && response.json())
      .then(data => {
        this.setState({
          answeredBranchPoints: {
            data,
            loading: false,
            loaded: true,
          }
        })
      });
  };

  fetchNextBranchPoint = () => { // creates BP_C
    const { match: { params: { id,cfid } } } = this.props;
    const cfId=parseInt(cfid);
    const { answeredBranchPoints } = this.state;
    console.log("ANS:", answeredBranchPoints)
    PhoenixApiManager.getNextBranchPointForModelWithId(cfid) // creates/gets BP_C or next branch point
      .then(response => response.status === 200 && response.json())
      .then((data) => {
        this.setState({
          nextBranchPoint: {
            data,
            loading: false,
            loaded: true,
          },
          focusedBranchPoint: Object.keys(data).length !== 0 ? data : answeredBranchPoints.data[0]
        });
      });
      PhoenixApiManager.getAllBranchPointForModelWithId(cfid) // creates/gets BP_C or next branch point
      .then(response => response.status === 200 && response.json())
      .then((data) => {
        const postRequestDataMap: any = {}
        const postRequestData: any = []
        data.forEach((bp:any, idx: number)=>{
          postRequestData.push(bp)
          postRequestDataMap[bp.name] = idx
        })
        this.setState({
          postRequestData: postRequestData,
          postRequestDataMap: postRequestDataMap,
          allBranchPoints: {
            data,
            loading: false,
            loaded: true,
          },
          focusedBranchPoint: Object.keys(data).length !== 0 ? data : answeredBranchPoints.data[answeredBranchPoints.data.length - 1]
        })
      });
  };

  getTemplateId = () => {
    const { templateID } = this.props;
    return templateID;
  };

  doesNextQuestionExist = () => {
    const { nextBranchPoint } = this.state;
    const { data } = nextBranchPoint;
    return (Object.keys(data).length !== 0) && data != {};
  };

  haveBranchPointTemplateAndAnsweredQuestionsLoaded = () => {
    const { answeredBranchPoints, templateBranchPoints } = this.state;
    return answeredBranchPoints.loaded && templateBranchPoints.loaded;
  };

  getBPFromTemplateWithKey = (key: any) => {
    const { templateBranchPoints } = this.state;
    if(key===undefined || key===null){
      return key;
    }
    if (key in templateBranchPoints) {
      return templateBranchPoints.data[key];
    }

    // remove last underscore with digits _d*
    const truncatedKey = key.replace(/(([_][\d]+)$)/, '');
    return templateBranchPoints.data[truncatedKey];
  };


  checkDisabledNext=(endStateNotSent,nextBP,nextQuestion)=>{
    console.log(endStateNotSent, 'mike', nextBP, 'menz', nextQuestion, 'lol')
    let or_res=nextBP||nextQuestion

    if (endStateNotSent===true){
      return true
    }
    return or_res;
  };

generateBranchPointQuestionAndResponse = () => {
    const { allBranchPoints, templateBranchPoints } = this.state
    if (!this.haveBranchPointTemplateAndAnsweredQuestionsLoaded()) {
      return [];
    }

    const templateAndAnswers: any = []

    // will iterate through all bps
    allBranchPoints.data.forEach((bp: any)=>{
      const name = bp.name;
      const templateBP = this.getBPFromTemplateWithKey(name);

      templateAndAnswers.push({
        // question bits
        name,
        constant: templateBP.template_fault_tree_id === null,
        successQuestionPrompt: templateBP.descriptions.success,
        failureQuestionPrompt: templateBP.descriptions.failure,
        // response bits
        successResponse: '',
        failureResponse: '',
        label: {
          name: '',
          description: '',
        },
        constantValue: undefined,
      });
      
    })
    return templateAndAnswers
  }

  doesBranchPointExist = (bp: any) => {
    const { answeredBranchPoints } = this.state;
    return answeredBranchPoints.data.find((e: any) => e.name === bp);
  };



  handleAnswerUpdated = (event: any, updatedForm: any) => { // called when hit save&continue
    const { history, match: { params: { id,cfid } }, onResponseUpdated } = this.props;
    const cfId=parseInt(cfid);
    const payload = JSON.stringify(updatedForm);
    const BP = updatedForm.name;

    const { answeredBranchPoints } = this.state;
    this.setState({isBuildingBP: true})
    if (!this.doesBranchPointExist(BP)) {
      PhoenixApiManager.postBranchPointResponseForModelWithId(cfId, payload)
        .then(response => response.status === 201 && response.json())
        .then((data) => {
          this.setState({
            answeredBranchPoints: {
              ...answeredBranchPoints,
              data: [...answeredBranchPoints.data, data],
            },
          });
        })
        .then(()=> this.setState({isBuildingBP: false}))
        .then(() => this.fetchNextBranchPoint()) // creates next branch point
        .then(() => onResponseUpdated());
    } else {
      PhoenixApiManager.patchBranchPointResponseForModelWithId(cfId, BP, payload)
        .then(response => response.status === 200 && response.json())
        .then(()=> this.setState({isBuildingBP: false}))
        .then(() => this.fetchNextBranchPoint())
        .then(() => onResponseUpdated());
    }
  };

  allDataLoaded = () => {
    const { nextBranchPoint, answeredBranchPoints, templateBranchPoints } = this.state;
    return nextBranchPoint.loaded && answeredBranchPoints.loaded && templateBranchPoints.loaded;
  };

  setFocusedBranchPoint = (idx: number) => {
    const { postRequestData } = this.state;
    this.setState({
      focusedBranchPoint: idx !== null && postRequestData[idx] ? postRequestData[idx] : idx
    })
  };

  setToBuildingMode = (theBool: boolean) => {
    const {isBuildingBP} = this.state

    this.setState({isBuildingBP: theBool})
  }

    onBPFormChange = (key: string, value: any, idx: any, isLabel: bool, description: any) =>{
    const tempPostArr = [...this.state.postRequestData]
    console.log(tempPostArr, idx)
    const tempPost  = {...tempPostArr[idx]}
    if(isLabel){
      const descriptionTemp = description ? { ...description}: {}
      tempPost['label'] = {...tempPostArr[idx].label, [key]: value, ...descriptionTemp}
    } else {
      tempPost[key] = value
    }
    tempPostArr[idx] = tempPost
    
    this.setState({
      postRequestData: tempPostArr
    })
  }

  bpFormOnsubmit = () => {
    this.setState({ postBPLoading: true, estimatedBPTime: 0 });
    const postRequestData: any = [...this.state.postRequestData];
    let finalPostRequestData: any = [];
    let estimatedBPTime = 0;
  
    for (let data of postRequestData) {
      if (!data.completed) {
        if ('failure_probability' in data) {
          // Safely handle failure_probability as a number
          let failureProb = Number(data['failure_probability']);
          
          // If it's 0 or 0.0, set to 0.00000001 to avoid backend issues and format to 8 decimals
          if (failureProb === 0) {
            failureProb = 0.00000001;
            data['failure_probability'] = failureProb.toFixed(8);  // Ensuring 8 decimal places
          } else {
            // Otherwise, use the user's input directly (no formatting)
            data['failure_probability'] = data['failure_probability'];
          }
        } else {
          estimatedBPTime += 1.5;
        }
        finalPostRequestData.push(data);
      }
    }
  
    this.setState({ estimatedBPTime });
  
    const { match: { params: { id, cfid } } } = this.props;
  
    PhoenixApiManager.postBranchPointResponseForModelWithId(cfid, JSON.stringify(finalPostRequestData))
      .then(res => {
        this.setState({ postBPLoading: false });
        this.fetchNextBranchPoint();
      })
      .catch(err => {
        this.setState({ postBPLoading: false });
        this.fetchNextBranchPoint();
      });
  };
  
  
  

  convertTime = () =>{
    const { estimatedBPTime } = this.state
    if (estimatedBPTime % 2 == 0){
      return `Estimated Time: ${estimatedBPTime} minute(s)`
    } else {
      return `Estimated Time: ${Math.floor(estimatedBPTime)} minute(s) and 30 seconds`
    }
  }

  render() {
    const {
      classes, depth, intl, match: { params: { id, cfid } }, history
    } = this.props;

    const { graph, descriptions, misc } = this.state;
    const { nextBranchPoint, focusedBranchPoint, isBuildingBP } = this.state;
    const generatedBPsWithResponses = this.generateBranchPointQuestionAndResponse();
    const nextQuestionExists = this.doesNextQuestionExist();
    const allDataLoaded = this.allDataLoaded();
    const bpdLabel = intl.formatMessage({ id: 'analysis.bpd' });
    if (!allDataLoaded) {
      return (
        
        <React.Fragment>
          <PopUpForBP/>
          <BreadcrumbsItem to={`/model/${id}/main-analysis/${cfid}`}>
            {intl.formatMessage({ id: `${this.state.cfname} ` })}
          </BreadcrumbsItem>
          <BreadcrumbsItem to={`/model/${id}/main-analysis/${cfid}/bp`}>{bpdLabel}</BreadcrumbsItem>
          <TreeReferenceButtonBP
        tooltipTitle={intl.formatMessage({ id: 'checkMasterESD' })}
        title="Master ESD"
        graphView={<HCLTreeMxGraphView data={this.state.masterESD} />}
      />

          <Grid container spacing={24}>
            <Grid item xs={12}>
              <CircularIndeterminate size={80} color="secondary" />
            </Grid>
          </Grid>
        </React.Fragment>
      );
    }
    if (generatedBPsWithResponses.length === 0 && !nextQuestionExists && allDataLoaded) {
      return (
        <React.Fragment>
          <PopUpForBP/>
          <BreadcrumbsItem to={`/model/${id}/main-analysis/${cfid}`}>
            {intl.formatMessage({ id: `${this.state.cfname} ` })}
          </BreadcrumbsItem>
          <BreadcrumbsItem to={`/model/${id}/main-analysis/${cfid}/bp`}>{bpdLabel}</BreadcrumbsItem>
          <TreeReferenceButtonBP
        tooltipTitle={intl.formatMessage({ id: 'checkMasterESD' })}
        title="Master ESD"
        graphView={<HCLTreeMxGraphView data={this.state.masterESD} />}
      />
          <Grid container spacing={24}>
            <Grid item xs={12} className={classes.cardFrame}>
              <Typography align="center" variant="h6" className={classes.message}>{intl.formatMessage({ id: 'analysis.noBPDLabel' })}</Typography>
            </Grid>
          </Grid>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <PopUpForBP/>
        <BreadcrumbsItem to={`/model/${id}/main-analysis/${cfid}`}>
          {intl.formatMessage({ id: `${this.state.cfname} ` })}
        </BreadcrumbsItem>
        <BreadcrumbsItem to={`/model/${id}/main-analysis/${cfid}/bp`}>{bpdLabel}</BreadcrumbsItem>
        <TreeReferenceButtonBP
        tooltipTitle={intl.formatMessage({ id: 'checkMasterESD' })}
        title="Master ESD"
        graphView={<HCLTreeMxGraphView data={this.state.masterESD} />}
      />
        <Grid container spacing={1}>
          <Grid item xs={7} className={classes.cardFrame}>
            <BPViewer
              graph={graph}
              branchPointMappings={generatedBPsWithResponses}
              focusedBranchPoint={focusedBranchPoint}
              cfid={cfid}
            />
          </Grid>
          <Grid item xs={5} className={classes.cardFrame}>
            <Card className={classes.card}>
            <NotePadButton
            cfId={cfid}
            title={'Notepad'}
            />  
              <div className={classes.header}>
                <Typography variant="h5">
                  {intl.formatMessage({ id: 'analysis.bpd' })}
                </Typography>
                <Typography variant="caption">
                  {intl.formatMessage({ id: 'analysis.bpdDesc' })}
                </Typography>
                <div>
                  <FormControl style={{minWidth: '200px', marginBottom: '20px'}}>
                    <InputLabel id="label-id">Copy from</InputLabel>
                    <Select
                      labelId="label-id"
                      id="select-id"
                      value={this.state.copyCFID}
                      onChange={async (evt) => { await this.setState({copyCFID: evt.target.value})}}
                    >
                      <MenuItem value={null}>
                        <em>None</em>
                      </MenuItem>
                      {
                        // here below, it's getting all the completed crit functions to allow you to copy from
                        // instead we want only the one we copied from in CRT
                        // useContext to get it done, pass in the copied function ID?
                        localStorage.getItem("clickedCopyCF") === "true" && this.state.completedCriticalFunctions && this.state.completedCriticalFunctions.map(item => { if (item.crt_model === parseInt(localStorage.getItem("copiedCFID"))) return (
                          <MenuItem value={item.crt_model}>{item.title}</MenuItem>
                        )})
                        // this.state.completedCriticalFunctions && this.state.completedCriticalFunctions
                      }
                    </Select>
                    {this.state.copyCFID===null? (null):<><Typography><CircularProgress/>Copying... (this can take a few minutes)</Typography></>}
                  </FormControl>
                </div>
                <Divider />
              </div>
              {/* <Typography align="center" variant="h6" className={classes.inline_message}>
              #Please re-label the relevant BPs, and delete the non-relevant ones, as instructed by the pop-ups that appear when you answer the questions. Note that if you are asked to label the same BP, it is because it appears more than once in the CRT. Label accordingly (e.g. H1, H2, H3...). Please click "save" before going to the next page #
              </Typography> */}
                

              <div className={classes.stepper}>
                {
                  descriptions &&
                  <>
                  {
                     isBuildingBP ? <Grid container spacing={24}>
                  <Grid item xs={12}><Typography>Creating branch point...</Typography>
                    <CircularIndeterminate size={80} color="secondary" /> 
                  </Grid>
                </Grid> : null
                  } 
                  <BPHorizontalStepper
                    misc={misc}
                    descriptions={descriptions}
                    cfid={cfid}
                    allBPs={generatedBPsWithResponses}
                    answered={generatedBPsWithResponses}
                    onAnswerUpdate={this.handleAnswerUpdated}
                    setFocusedBranchPoint={(idx) => this.setFocusedBranchPoint(idx)}
                    isBuildingBP={isBuildingBP}
                    setToBuildingMode={()=> this.setToBuildingMode}
                    postRequestData={this.state.postRequestData}
                    postRequestDataMap={this.state.postRequestDataMap}
                    onBPFormChange={this.onBPFormChange}
                    bpFormOnsubmit={this.bpFormOnsubmit}
                    postBPLoading={this.state.postBPLoading}
                    convertTime={this.convertTime}
                  />                  
                  </>

                }
              </div>
              {/* <div className={classes.stepper} ><EndStateSelection cfid={cfid} id={id} handler={this.handleEndStateSent}/></div>               */}
              <div className={classes.navigation}>
                <Button
                  className={classes.fullHeight}
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={this.checkDisabledNext(this.state.endStateNotSent,nextBranchPoint.loading,nextQuestionExists)}
                  onClick={() => history.push(`/model/${id}/main-master-esd`)}
                >
                  {intl.formatMessage({ id: "analysis.next" })}
                </Button>
              </div>
            </Card>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(injectIntl(BranchPointDescriptionBuilder));
