import ApiManager from 'hcla-web-frontend-primitives/app/Api/ApiManager';
import Axios, {AxiosResponse} from "axios";
import AuthService from "hcla-web-frontend-primitives/app/Api/AuthService";
import { Description } from '@material-ui/icons';

export type TemplateTree = {
  id: number;
  title: string;
  date_created: string;
  date_modified: string;
  creator: number;
  description: string;
  hcl_model: number;
  questionnaire: {
    crt: {
      decision_points: {
        [key: string]: {
          index: number;
          branch_name: string;
          question: string;
          description: string;
          response_options: {
            Yes: 0;
            No: 1;
          };
        };
      };
      branch_points: {
        [key: string]: {
          branch_name: string;
          descriptions: {
            success: string;
            failure: string;
          };
          template_fault_tree_id: number;
        };
      };
    };
    hfe: any;
    pif: any;
  };
}

export type NextDecisionPoint = {
  name: string;
  template: {
    index: number;
    branch_name: string;
    question: string;
    description: string;
    response_options: {
      Yes: 0;
      No: 1;
    };
  };
  branch_name: string;
}
 
export type AnsweredDecisionPoint = {
  response: "Yes" | "No";
  description: string;
  name: string;
  template: {
    index: number;
    branch_name: string;
    question: string;
    description: string;
    response_options: {
      Yes: 0;
      No: 1;
    };
  };
  branch_name: string;
}

class PhoenixApiManager extends ApiManager {

  static ENDPOINT = `${ApiManager.API_ENDPOINT}/phoenix`;

  static MAIN_ENDPOINT=`${ApiManager.API_ENDPOINT}/phoenix/main-model`

  static TEMPLATE_PATH = '/template';

//updated api



  static getMasterEsd(modelId: number) {
    const url = `${PhoenixApiManager.MAIN_ENDPOINT}/${modelId}/master-esd/`;
    return Axios.get(url, {
      headers: {
        Authorization: `JWT ${AuthService.getToken()}`
      }
    });
  }


  static patchMasterEsd(modelId: number) {
    const url = `${PhoenixApiManager.MAIN_ENDPOINT}/${modelId}/master-esd/`;
    return Axios.patch(url, {
      headers: {
        Authorization: `JWT ${AuthService.getToken()}`
      }
    });
  }

  /*
  public static patchHclTreeDataOnlyJson(treeId: number, type: TreeTypes, data: FaultTreeMxGraphJSON | EventTreeMxGraphJSON | BayesianNetworkMxGraphJSON) {
    const payload = JSON.stringify({
      tree_type: type,
      tree_data: data,
    });
    return ApiManager.patch(`${HclApiManager.ENDPOINT}/tree/${treeId}/`, payload);
  }
  */

  public static patchHclTreeDataOnlyJson(modelId: number, data: FaultTreeMxGraphJSON | EventTreeMxGraphJSON | BayesianNetworkMxGraphJSON) {
    let request = Axios.create({
      method: 'PATCH',
      baseURL: `${PhoenixApiManager.MAIN_ENDPOINT}/${modelId}/master-esd/`,
      headers: {
        Authorization: `JWT ${AuthService.getToken()}`
      },
    });

    const payload = {
      tree_type: 'e',
      tree_data: data,
    };

    return request.patch(null, payload);
  }


  static isOldModel(modelId:number){
    
    const url=`${PhoenixApiManager.ENDPOINT}/${modelId}`;
    return Axios.get(url, {
      headers: {
        Authorization: `JWT ${AuthService.getToken()}`
      }
    }).then(function(res){
      return true
    }).catch(function(error){
      console.log(error)
      return false
    })
  }

  static getMainCriticalFunctions(modelId: number, include_details: boolean = false) {
    let url = `${PhoenixApiManager.MAIN_ENDPOINT}/${modelId}/cf/`;
    if (include_details) url += `?include_crt=true`
    return Axios.get(url, {
      headers: {
        Authorization: `JWT ${AuthService.getToken()}`
      }
    });
  }


  // api/phoenix/main-model/2321/cf/list-by-main-model

  // you will only get models that are shared with you and it filters by template ID

  static getCFListForCopy(modelId: number, include_details: boolean = false) {
    let url = `${PhoenixApiManager.MAIN_ENDPOINT}/${modelId}/cf/list-by-main-model`;
    if (include_details) url += `?include_crt=true`
    return Axios.get(url, {
      headers: {
        Authorization: `JWT ${AuthService.getToken()}`
      }
    });
  }

  // api/phoenix/main-model/1208/cf/1096/copy-cf/

  static copyCRTWithCfId(modelId: number, critical_function: number, data: any) {
    const url = `${PhoenixApiManager.MAIN_ENDPOINT}/${modelId}/cf/${critical_function}/copy-cf/`;
    return Axios.post(url, data, {
      headers: {
        Authorization: `JWT ${AuthService.getToken()}`
      }
    });
  }



  static createMainCriticalFunction(modelId: number, payload: any, onSuccessCallback?: any, onFailCallback?: any) {
    const url = `${PhoenixApiManager.MAIN_ENDPOINT}/${modelId}/cf/`;
    return Axios.post(url, payload, {
      headers: {
        Authorization: `JWT ${AuthService.getToken()}`
      },
    });
  }

  // static createMainCriticalFunction(modelId: number, payload: any, onSuccessCallback?: any, onFailCallback?: any) {
  //   const url = `${PhoenixApiManager.MAIN_ENDPOINT}/${modelId}/cf/`;
  //   return Axios({
  //     method: 'post',
  //     url: url,
  //     data: {
  //       title: modelId
  //     },
  //     headers: {Authorization: `JWT ${AuthService.getToken()}`},

  //   });
  // }



  static getMainCriticalFunction(cfId: number, modelId: number, override?: any, onSuccessCallback?: any, onFailCallback?: any) {
    const url = `${PhoenixApiManager.MAIN_ENDPOINT}/${modelId}/cf/${cfId}/`;
    return Axios.get(url, {
      headers: {
        Authorization: `JWT ${AuthService.getToken()}`
      }
    });
  }

  static deleteMainCriticalFunction(modelId: number, cfId: number, onSuccessCallback?: any, onFailCallback?: any) {
    const url = `${PhoenixApiManager.MAIN_ENDPOINT}/${modelId}/cf/${cfId}/`;
    return Axios.delete(url, {
      headers: {
        Authorization: `JWT ${AuthService.getToken()}`
      }
    });
  }

  static getMainModels (limit: any, offset: any, onSuccessCallback?: any, onFailCallback?: any) {
    if (limit) {
      return ApiManager.getWithOptions(
        `${PhoenixApiManager.MAIN_ENDPOINT}/?include_model_data=false&limit=${limit}&offset=${offset}`,
        onSuccessCallback,
        onFailCallback,
      );
    }
    return ApiManager.getWithOptions(
      `${PhoenixApiManager.MAIN_ENDPOINT}/?include_model_data=false`,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static searchMainModels(keyword: string, limit?: number, offset?: number) {
    let url;
    url = `${PhoenixApiManager.MAIN_ENDPOINT}/main-model/search/?key=${keyword}`;
    if (limit) {
      url = `${PhoenixApiManager.MAIN_ENDPOINT}/main-model/search/?key=${keyword}&limit=${limit}&offset=${offset}`;
    }
    return Axios.get(url, {
      headers: {
        Authorization: `JWT ${AuthService.getToken()}`
      }
    });
  }
  

  static createNewMainModel(data: any, onSuccessCallback?: any, onFailCallback?: any) {
    const url = `${PhoenixApiManager.MAIN_ENDPOINT}/`;
    return Axios.post(url, data,{
      headers: {
        Authorization: `JWT ${AuthService.getToken()}`
      }
    });
  }

  static openMainModel(modelId: number) {
    const url = `${PhoenixApiManager.MAIN_ENDPOINT}/${modelId}/`;
    return Axios.get(url, {
      headers: {
        Authorization: `JWT ${AuthService.getToken()}`
      }
    });
  }

  static openMainModelNote(modelId: number) {
    const url = `${PhoenixApiManager.ENDPOINT}/model/${modelId}/`;
    return Axios.get(url, {
      headers: {
        Authorization: `JWT ${AuthService.getToken()}`
      }
    });
  }

 
  static updateMainModel(modelId: number) {
    const url = `${PhoenixApiManager.MAIN_ENDPOINT}/${modelId}/`;
    return Axios.put(url, {
      headers: {
        Authorization: `JWT ${AuthService.getToken()}`
      }
    });
  }

  // use call below for misc calls in the input field for BP labels, do not change URL, DO NOT MODIFY ENPOINT, USE DIFFERENT ENDPOINT
  //FOR MODEL SETTING

  static settingPartialUpdateMainModel(modelId: number, payload: any) {
    const url = `${PhoenixApiManager.MAIN_ENDPOINT}/${modelId}/`;
    return Axios.patch(url, payload, {
      headers: {
        Authorization: `JWT ${AuthService.getToken()}`
      }
    });
  }

  static partialUpdateMainModel(modelId: number, payload: any) {
    const url = `${PhoenixApiManager.ENDPOINT}/model/${modelId}/`;
    return Axios.patch(url, payload, {
      headers: {
        Authorization: `JWT ${AuthService.getToken()}`
      }
    });
  }

  static deleteMainModel(modelId: number) {
    const url = `${PhoenixApiManager.MAIN_ENDPOINT}/${modelId}/`;
    return Axios.delete(url, {
      headers: {
        Authorization: `JWT ${AuthService.getToken()}`
      }
    });
  }

  // static getCfFunctionDetails(cfId: number) {
  //   const url = `${PhoenixApiManager.MAIN_ENDPOINT}/${cfId}/`;
  //   return Axios.get(url, {
  //     headers: {
  //       Authorization: `JWT ${AuthService.getToken()}`
  //     }
  //   });
  // }

  static getCfFunctionDetails(cfId:number,onSuccessCallback?: any, onFailCallback?: any){
    return ApiManager.getWithOptions(
      `${PhoenixApiManager.ENDPOINT}/model/${cfId}/`,
      onSuccessCallback,
      onFailCallback,
    );
  }
  static getEndstates(modelId: number, critical_function: number) {
    const url = `${PhoenixApiManager.MAIN_ENDPOINT}/${modelId}/cf/${critical_function}/end-state/`;
    return Axios.get(url, {
      headers: {
        Authorization: `JWT ${AuthService.getToken()}`
      }
    });
  }

  static postEndstates(modelId: number, critical_function: number, data: any) {
    const url = `${PhoenixApiManager.MAIN_ENDPOINT}/${modelId}/cf/${critical_function}/end-state/`;
    return Axios.post(url, data, {
      headers: {
        Authorization: `JWT ${AuthService.getToken()}`
      }
    });
  }

  static quantifyMainPhoenixTreeForModelWithId(treeId: any, modelId: any,dependency:any, title:any, configuration?: any, onSuccessCallback?: any, onFailCallback?: any) {
    const defaultConfig =
      {
        quantify: {
          type: 'point_estimate',
          targets: '__all__',
          importance: {
            events: 'all',
            measures: ['all'],
          }
        },
      };

    const configConstructor = () => {
      let constructor = null;
      if (configuration && 'constructor' in configuration) {
        constructor = {...configuration.constructor};
        delete configuration.constructor;
      }
      return constructor;
    };

    const realConfig = configuration ? configuration : defaultConfig
    realConfig.configuration = {...realConfig.configuration, dependency}

    const params = {
        ...realConfig,
          title:title
    };
    //change the Stringfy to be params
    const qp = JSON.stringify(params);
    return ApiManager.post(
      `${PhoenixApiManager.ENDPOINT}/main-model/${modelId}/quantification/`,
      qp,
      onSuccessCallback,
      onFailCallback,
    );
  }
  
  static getMainQuantificationResultsForPhoenixModelWithId(modelId: any, limit: number, offset: number, nextOrPrev: any) {
    if (nextOrPrev){
      return ApiManager.getWithOptions(
        `${PhoenixApiManager.ENDPOINT.slice(0, PhoenixApiManager.ENDPOINT.length-12)}${nextOrPrev}`);
    } else{
    return ApiManager.getWithOptions(
      `${PhoenixApiManager.ENDPOINT}/main-model/${modelId}/quantification/${limit ? `?limit=${limit}&offset=${offset}`:""}`);
    }
  }

  static getDetailedMainQuantificationForView(modelId: any, cfid: number) {
    return ApiManager.getWithOptions(
      `${PhoenixApiManager.ENDPOINT}/main-model/${modelId}/quantification/${cfid}`);
  }

//Dependency calls
  static addToGroup(modelId: number, pif: any, payload: any) {
    const url = `${PhoenixApiManager.MAIN_ENDPOINT}/${modelId}/dependency/${pif}/`;
    return Axios.put(url, payload, {
      headers: {
        Authorization: `JWT ${AuthService.getToken()}`
      }
    });
  }

  static getGroups(modelId: number, pif: any) {
    const url = `${PhoenixApiManager.MAIN_ENDPOINT}/${modelId}/dependency/${pif}/`;
    return Axios.get(url, {
      headers: {
        Authorization: `JWT ${AuthService.getToken()}`
      }
    });
  }

  static getOptionsForGroups(modelId: number, pif: any) {
    const url = `${PhoenixApiManager.MAIN_ENDPOINT}/${modelId}/dependency/${pif}/options/`;
    return Axios.get(url, {
      headers: {
        Authorization: `JWT ${AuthService.getToken()}`
      }
    });
  }

//*********************************************NOT MAIN MODEL STUFFS********************************* */
  static getCrewResponseTreeAnalysisEventTree(cfId: number) {
    const url = `${PhoenixApiManager.ENDPOINT}/model/${cfId}/crt/event-tree/`;
    return Axios.get(url, {
      headers: {
        Authorization: `JWT ${AuthService.getToken()}`
      }
    });
  }

  static getTemplateTree(templateId: number) { // this is called in order to get the decision points, which then lead to the questions
    const url = `${PhoenixApiManager.ENDPOINT}${PhoenixApiManager.TEMPLATE_PATH}/${templateId}/`;
    return Axios.get<any, AxiosResponse<TemplateTree>>(url, {
      headers: {
        Authorization: `JWT ${AuthService.getToken()}`
      }
    });
  }

  static getAnsweredDecisionPoints(modelId: number) {
    const url = `${PhoenixApiManager.ENDPOINT}/model/${modelId}/crt/decision-point/`;
    return Axios.get<any, AxiosResponse<AnsweredDecisionPoint[]>>(url, {
      headers: {
        Authorization: `JWT ${AuthService.getToken()}`
      }
    });
  }

  static getNextDecisionPoint(modelId: number) {
    const url = `${PhoenixApiManager.ENDPOINT}/model/${modelId}/crt/decision-point/next/`;
    return Axios.get<any, AxiosResponse<NextDecisionPoint>>(url, {
      headers: {
        Authorization: `JWT ${AuthService.getToken()}`
      }
    });
  }

  static getPhoenixTree(treeId: number) {
    const url = `${PhoenixApiManager.ENDPOINT}/tree/${treeId}/data/`;
    return Axios.get(url, {
      headers: {
        Authorization: `JWT ${AuthService.getToken()}`
      }
    });
  }

  static getAnsweredHumanFailureEvent(modelId: number, hfeName: string) {
    const url = `${PhoenixApiManager.ENDPOINT}/model/${modelId}/crt/branch-point/${hfeName}/`;
    return Axios.get(url, {
      headers: {
        Authorization: `JWT ${AuthService.getToken()}`
      }
    });
  }

  static getHumanFailureEvent(modelId: number, hfeName: string) {
    const url = `${PhoenixApiManager.ENDPOINT}/model/${modelId}/hfe/${hfeName}/`;
    return Axios.get(url, {
      headers: {
        Authorization: `JWT ${AuthService.getToken()}`
      }
    });
  }

  static getPhoenixTemplateFaultTree(treeId: number) {
    const url = `${ApiManager.API_ENDPOINT}/hcl/tree/${treeId}/data/`;
    return Axios.get(url, {
      headers: {
        Authorization: `JWT ${AuthService.getToken()}`
      }
    });
  }

  // legacy
  static getPhoenixCRTAsEventTreeForModelWithId(modelId: any, onSuccessCallback?: any, onFailCallback?: any) {
    return ApiManager.getWithOptions(
      `${PhoenixApiManager.ENDPOINT}/model/${modelId}/crt/event-tree/`,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static getPhoenixTreeDataOnlyXml(treeId: any, onSuccessCallback: any, onFailCallback: any) {
    return ApiManager.getModelXml(
      `${PhoenixApiManager.ENDPOINT}/tree/${treeId}/data/`,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static getPhoenixTreeDataOnlyJson(treeId: any, onSuccessCallback: any, onFailCallback: any) {
    return ApiManager.getWithOptions(
      `${PhoenixApiManager.ENDPOINT}/tree/${treeId}/data/`,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static getPhoenixTemplateTreeDataOnlyJson(treeId: any, onSuccessCallback: any, onFailCallback: any) {
    return ApiManager.getWithOptions(
      `${ApiManager.API_ENDPOINT}/hcl/tree/${treeId}/data/`,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static getPhoenixTreeWithMetadata(treeId: any, onSuccessCallback?: any, onFailCallback?: any) {
    return ApiManager.getWithOptions(
      `${PhoenixApiManager.ENDPOINT}/tree/${treeId}/?include_tree_data=true`,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static getPhoenixModelWithId(modelId: any, onSuccessCallback?: any, onFailCallback?: any) {
    return ApiManager.getWithOptions(
      `${PhoenixApiManager.ENDPOINT}/model/${modelId}/`,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static getPhoenixTreeMetadataOnly(treeId: any, onSuccessCallback: any, onFailCallback: any) {
    return ApiManager.getWithOptions(
      `${PhoenixApiManager.ENDPOINT}/tree/${treeId}`,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static getAnsweredDecisionPointsForModelWithId(modelId: any, onSuccessCallback: any, onFailCallback: any) {
    return ApiManager.getWithOptions(
      `${PhoenixApiManager.ENDPOINT}/model/${modelId}/crt/decision-point/`,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static quantifyPhoenixTreeForModelWithId(treeId: any, modelId: any, configuration?: any, onSuccessCallback?: any, onFailCallback?: any) {
    const defaultConfig =
      {
        quantify: {
          type: 'point_estimate',
          targets: '__all__',
          importance: {
            events: 'all',
            measures: ['all'],
          }
        },
      };

    const configConstructor = () => {
      let constructor = null;
      if (configuration && 'constructor' in configuration) {
        constructor = {...configuration.constructor};
        delete configuration.constructor;
      }
      return constructor;
    };

    const params = {
      configuration: {
        constructor: {
          tree_id: treeId,
          ...configConstructor()
        },
        ...(configuration ? configuration : defaultConfig)
      }
    };
    const qp = JSON.stringify(params);
    return ApiManager.post(
      `${PhoenixApiManager.ENDPOINT}/model/${modelId}/quantification/`,
      qp,
      onSuccessCallback,
      onFailCallback,
    );
  }

  // static quantifyMainPhoenixTreeForModelWithId(treeId: any, modelId: any, configuration?: any, onSuccessCallback?: any, onFailCallback?: any) {
  //   const defaultConfig =
  //     {
  //       quantify: {
  //         type: 'point_estimate',
  //         targets: '__all__',
  //         importance: {
  //           events: 'all',
  //           measures: ['all'],
  //         }
  //       },
  //     };

  //   const configConstructor = () => {
  //     let constructor = null;
  //     if (configuration && 'constructor' in configuration) {
  //       constructor = {...configuration.constructor};
  //       delete configuration.constructor;
  //     }
  //     return constructor;
  //   };

  //   const params = {
  //     configuration: {
  //       constructor: {
  //         tree_id: treeId,
  //         ...configConstructor()
  //       },
  //       ...(configuration ? configuration : defaultConfig)
  //     }
  //   };
  //   const qp = JSON.stringify(params);
  //   return ApiManager.post(
  //     `${PhoenixApiManager.ENDPOINT}/model/${modelId}/quantification/`,
  //     qp,
  //     onSuccessCallback,
  //     onFailCallback,
  //   );
  // }

  static getQuantificationResultsForPhoenixModelWithId(modelId: any, onSuccessCallback: any, onFailCallback: any) {
    return ApiManager.getWithOptions(
      `${PhoenixApiManager.ENDPOINT}/model/${modelId}/quantification/`,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static getQuantificationResultsForPhoenixTreeAndModelWithId(treeId: any, modelId: any, onSuccessCallback?: any, onFailCallback?: any) {
    return ApiManager.getWithOptions(
      `${PhoenixApiManager.ENDPOINT}/model/${modelId}/quantification/?tree_id=${treeId}`,
      onSuccessCallback,
      onFailCallback,
    );
  }




  static getHFEsForModelWithId(modelId: any, onSuccessCallback?: any, onFailCallback?: any) {
    return ApiManager.getWithOptions(
      `${PhoenixApiManager.ENDPOINT}/model/${modelId}/hfe/`,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static getHFEForModelWithId(modelId: any, hfeName: any, onSuccessCallback: any, onFailCallback: any) {
    return ApiManager.getWithOptions(
      `${PhoenixApiManager.ENDPOINT}/model/${modelId}/hfe/${hfeName}/`,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static postHFEsForModelWithId(
    modelId: any,
    payload: any,
    onSuccessCallback: any,
    onFailCallback: any,
  ) {
    return ApiManager.post(
      `${PhoenixApiManager.ENDPOINT}/model/${modelId}/hfe/`,
      payload,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static patchHFEForModelWithId(
    modelId: any,
    hfeName: any,
    payload: any,
    onSuccessCallback: any,
    onFailCallback: any,
  ) {
    return ApiManager.patch(
      `${PhoenixApiManager.ENDPOINT}/model/${modelId}/hfe/${hfeName}/`,
      payload,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static postHFEForModelWithId(
    modelId: any,
    payload: any,
    onSuccessCallback?: any,
    onFailCallback?: any,
  ) {
    return ApiManager.post(
      `${PhoenixApiManager.ENDPOINT}/model/${modelId}/hfe/`,
      payload,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static getCFMsForModelWithIdAndHFE(
    modelId: any,
    hfeName: any,
    onSuccessCallback?: any,
    onFailCallback?: any,
  ) {
    return ApiManager.getWithOptions(
      `${PhoenixApiManager.ENDPOINT}/model/${modelId}/hfe/${hfeName}/cfm/`,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static getNextBranchPointForModelWithId(
    modelId: any,
    onSuccessCallback?: any,
    onFailCallback?: any,
  ) {
    return ApiManager.getWithOptions(
      `${PhoenixApiManager.ENDPOINT}/model/${modelId}/crt/branch-point/next/`,
      null,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static getAllBranchPointForModelWithId(
    modelId: any,
    onSuccessCallback?: any,
    onFailCallback?: any,
  ) {
    return ApiManager.getWithOptions(
      `${PhoenixApiManager.ENDPOINT}/model/${modelId}/crt/branch-point/get_all_bps/`,
      null,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static postBranchPointResponseForModelWithId( // This api call will fail sometimes...
    modelId: any,
    payload: any,
    onSuccessCallback?: any,
    onFailCallback?: any,
  ) {
    return ApiManager.post(
      `${PhoenixApiManager.ENDPOINT}/model/${modelId}/crt/branch-point/`,
      payload,
      null,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static patchBranchPointResponseForModelWithId(
    modelId: any,
    DP: any,
    payload: any,
    onSuccessCallback?: any,
    onFailCallback?: any,
  ) {
    return (
      ApiManager.patch(
        `${PhoenixApiManager.ENDPOINT}/model/${modelId}/crt/branch-point/${DP}/`,
        payload,
        onSuccessCallback,
        onFailCallback,
      )
    );
  }

  static getNextDecisionPointForModelWithId(
    modelId: any,
    onSuccessCallback: any,
    onFailCallback: any,
  ) {
    return ApiManager.getWithOptions(
      `${PhoenixApiManager.ENDPOINT}/model/${modelId}/crt/decision-point/next/`,
      null,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static patchDecisionPointResponseForModelWithId(
    modelId: any,
    DP: any,
    payload: any,
    onSuccessCallback?: any,
    onFailCallback?: any,
  ) {
    return ApiManager.patch(
      `${PhoenixApiManager.ENDPOINT}/model/${modelId}/crt/decision-point/${DP}/`,
      payload,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static postDecisionPointResponseForModelWithId(
    modelId: any,
    payload: any,
    onSuccessCallback?: any,
    onFailCallback?: any,
  ) {
    return ApiManager.post(
      `${PhoenixApiManager.ENDPOINT}/model/${modelId}/crt/decision-point/`,
      payload,
      null,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static getCFMForModelWithIdAndHFE(
    modelId: any,
    hfeName: any,
    cfmName: any,
    onSuccessCallback?: any,
    onFailCallback?: any,
  ) {
    return ApiManager.getWithOptions(
      `${PhoenixApiManager.ENDPOINT}/model/${modelId}/hfe/${hfeName}/cfm/${cfmName}/`,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static postCFMsForModelWithIdAndHFE(
    modelId: any,
    hfeName: any,
    payload: any,
    onSuccessCallback: any,
    onFailCallback: any,
  ) {
    return ApiManager.post(
      `${PhoenixApiManager.ENDPOINT}/model/${modelId}/hfe/${hfeName}/cfm/`,
      payload,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static postCFMForModelWithIdAndHFE(
    modelId: any,
    hfeName: any,
    payload: any,
    onSuccessCallback: any,
    onFailCallback: any,
  ) {
    return ApiManager.post(
      `${PhoenixApiManager.ENDPOINT}/model/${modelId}/hfe/${hfeName}/cfm/`,
      payload,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static getPIFsForModelWithIdAndHFEAndCFM(
    modelId: any,
    hfeName: any,
    cfmName: any,
    onSuccessCallback?: any,
    onFailCallback?: any,
  ) {
    return ApiManager.getWithOptions(
      `${PhoenixApiManager.ENDPOINT}/model/${modelId}/hfe/${hfeName}/cfm/${cfmName}/`,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static getTemplateForModelWithId(
    modelId: any,
    onSuccessCallback?: any,
    onFailCallback?: any,
  ) {
    return ApiManager.getWithOptions(
      `${PhoenixApiManager.ENDPOINT}/model/${modelId}${PhoenixApiManager.TEMPLATE_PATH}/`,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static getPIFForModelWithIdAndHFEAndCFM(
    modelId: any,
    hfeName: any,
    cfmName: any,
    pifName: any,
    onSuccessCallback?: any,
    onFailCallback?: any,
  ) {
    return ApiManager.getWithOptions(
      `${PhoenixApiManager.ENDPOINT}/model/${modelId}/hfe/${hfeName}/cfm/${cfmName}/pif/${pifName}/`,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static postPIFsForModelWithIdAndHFEAndCFM(
    modelId: any,
    hfeName: any,
    cfmName: any,
    payload: any,
    onSuccessCallback: any,
    onFailCallback: any,
  ) {
    return ApiManager.post(
      `${PhoenixApiManager.ENDPOINT}/model/${modelId}/hfe/${hfeName}/cfm/${cfmName}/pif/`,
      payload,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static postPIFForModelWithIdAndHFEAndCFM(
    modelId: any,
    hfeName: any,
    cfmName: any,
    payload: any,
    onSuccessCallback?: any,
    onFailCallback?: any,
  ) {
    return ApiManager.post(
      `${PhoenixApiManager.ENDPOINT}/model/${modelId}/hfe/${hfeName}/cfm/${cfmName}/pif/`,
      payload,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static patchPIFForModelWithIdAndHFEAndCFM(
    modelId: any,
    hfeName: any,
    cfmName: any,
    pifName: any,
    payload: any,
    onSuccessCallback?: any,
    onFailCallback?: any,
  ) {
    return ApiManager.patch(
      `${PhoenixApiManager.ENDPOINT}/model/${modelId}/hfe/${hfeName}/cfm/${cfmName}/pif/${pifName}/`,
      payload,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static deletePIFForModelWithIdAndHFEAndCFM(
    modelId: any,
    hfeName: any,
    cfmName: any,
    pifName: any,
    onSuccessCallback?: any,
    onFailCallback?: any,
  ) {
    return ApiManager.delete(
      `${PhoenixApiManager.ENDPOINT}/model/${modelId}/hfe/${hfeName}/cfm/${cfmName}/pif/${pifName}/`,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static getAnsweredBranchPointsForModelWithId(
    modelId: any,
    onSuccessCallback?: any,
    onFailCallback?: any,
  ) {
    return ApiManager.getWithOptions(
      `${PhoenixApiManager.ENDPOINT}/model/${modelId}/crt/branch-point/`,
      null,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static getAnsweredBranchPointForModelWithId(
    modelId: any,
    bp: any,
    onSuccessCallback?: any,
    onFailCallback?: any,
  ) {
    return ApiManager.getWithOptions(
      `${PhoenixApiManager.ENDPOINT}/model/${modelId}/crt/branch-point/${bp}/`,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static patchModel(
    modelId: any,
    data: any,
    onSuccessCallback: any,
    onFailCallback: any,
  ) {
    return ApiManager.patch(
      `${PhoenixApiManager.ENDPOINT}/model/${modelId}/`,
      data,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static patchMainModel(
    modelId: any,
    data: any,
    onSuccessCallback: any,
    onFailCallback: any,
  ) {
    return ApiManager.patch(
      `${PhoenixApiManager.ENDPOINT}/main-model/${modelId}/`,
      data,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static getModels (limit: any, offset: any, onSuccessCallback?: any, onFailCallback?: any) {
    if (limit) {
      return ApiManager.getWithOptions(
        `${PhoenixApiManager.ENDPOINT}/model/?include_model_data=false&limit=${limit}&offset=${offset}`,
        onSuccessCallback,
        onFailCallback,
      );
    }
    return ApiManager.getWithOptions(
      `${PhoenixApiManager.ENDPOINT}/model/?include_model_data=false`,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static getCollabModels (override: any, onSuccessCallback: any, onFailCallback: any) {
    return ApiManager.getModelsForType('phoenix', override, onSuccessCallback, onFailCallback);
  }

  // @ts-ignore
  static postNewModel = (data: any, onSuccessCallback?: any, onFailCallback?: any) => ApiManager.postNewModel('phoenix', data, onSuccessCallback, onFailCallback);

  static getTemplates = (limit?: any, offset?: any, onSuccessCallback?: any, onFailCallback?: any) => {
    if (limit) {
      return ApiManager.getWithOptions(`${PhoenixApiManager.ENDPOINT}${PhoenixApiManager.TEMPLATE_PATH}/?limit=${limit}&offset=${offset}`, onSuccessCallback, onFailCallback);
    }
    return ApiManager.getWithOptions(`${PhoenixApiManager.ENDPOINT}${PhoenixApiManager.TEMPLATE_PATH}/`, onSuccessCallback, onFailCallback);
  };

  static getTemplateWithId = (id: any, onSuccessCallback?: any, onFailCallback?: any) => ApiManager.getWithOptions(`${PhoenixApiManager.ENDPOINT}${PhoenixApiManager.TEMPLATE_PATH}/${id}/`, onSuccessCallback, onFailCallback);

  static postNewTemplate = (data: any, onSuccessCallback: any, onFailCallback: any) => ApiManager.post(`${PhoenixApiManager.ENDPOINT}${PhoenixApiManager.TEMPLATE_PATH}/`, data, onSuccessCallback, onFailCallback);

  static patchTemplate = (id: any, data: any, onSuccessCallback: any, onFailCallback: any) => ApiManager.patch(`${PhoenixApiManager.ENDPOINT}${PhoenixApiManager.TEMPLATE_PATH}/${id}/`, data, onSuccessCallback, onFailCallback);

  static deleteTemplate = (id: any, onSuccessCallback: any, onFailCallback: any) => ApiManager.delete(`${PhoenixApiManager.ENDPOINT}${PhoenixApiManager.TEMPLATE_PATH}/${id}/`, onSuccessCallback, onFailCallback);

  static getReport = (id: number, onSuccessCallback: any, onFailCallback: any) => {
    return fetch(`${process.env.REPORT_DOMAIN || 'report'}/phoenix/${id}`, {
      method: 'GET',
      headers: {
        Authorization: AuthService.getToken(),
      },
    });
  };

  static searchModels(keyword: string, limit?: number, offset?: number) {
    let url;
    url = `${PhoenixApiManager.ENDPOINT}/model/search/?key=${keyword}`;
    if (limit) {
      url = `${PhoenixApiManager.ENDPOINT}/model/search/?key=${keyword}&limit=${limit}&offset=${offset}`;
    }
    return Axios.get(url, {
      headers: {
        Authorization: `JWT ${AuthService.getToken()}`
      }
    });
  }


//SPECIAL NOTICE: THIS IS ORIGINALLY USING `${PhoenixApiManager.ENDPOINT}/cf-meta/?limit=${limit}&offset=${offset}`,
  //I CHANGED THIS FUNCTION TO GET THE ORIGINAL DATA
  static getCriticalFunctions(limit?: number, offset?: number, override?: any, onSuccessCallback?: any, onFailCallback?: any) {
    if (limit) {
      return ApiManager.getWithOptions(
        `${PhoenixApiManager.ENDPOINT}/cf-meta/?limit=${limit}&offset=${offset}`,
        override,
        onSuccessCallback,
        onFailCallback,
      );
    }
    return ApiManager.getWithOptions(
      `${PhoenixApiManager.ENDPOINT}/cf-meta/`,
      override,
      onSuccessCallback,
      onFailCallback,
    );
  }

  //SPECIAL NOTICE: THIS IS ORIGINALLY USING ${PhoenixApiManager.ENDPOINT}/cf-meta/${id}`
  //I CHANGED THIS FUNCTION TO GET THE ORIGINAL DATA
  static getCriticalFunction(id: number, override?: any, onSuccessCallback?: any, onFailCallback?: any) {
    return ApiManager.getWithOptions(
      `${PhoenixApiManager.ENDPOINT}/model/${id}/crt/`,
      override,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static getPreviousCriticalFunctions(prev: string, override?: any, onSuccessCallback?: any, onFailCallback?: any) {
    return ApiManager.getWithOptions(
      prev,
      override,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static getNextCriticalFunctions(next: string, override?: any, onSuccessCallback?: any, onFailCallback?: any) {
    return ApiManager.getWithOptions(
      next,
      override,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static createCriticalFunction(payload: any, onSuccessCallback?: any, onFailCallback?: any) {
    return ApiManager.post(
      `${PhoenixApiManager.ENDPOINT}/cf-meta/`,
      JSON.stringify(payload),
      onSuccessCallback,
      onFailCallback,
    );
  }

  static deleteCriticalFunction(id: any, onSuccessCallback?: any, onFailCallback?: any) {
    return ApiManager.delete(
      `${PhoenixApiManager.ENDPOINT}/cf-meta/${id}`,
      onSuccessCallback,
      onFailCallback,
    );
  }

  static getCriticalFunctionStatus(id: number, override?: any, onSuccessCallback?: any, onFailCallback?: any){
    return [false,false];
  }

}

export default PhoenixApiManager;
